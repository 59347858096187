.login .tjunction-logo img {
	max-height: 55px;
}

.login .login-left-container {
	padding: 50px 35px;
}

.login-left-container .tjunction-description {
	padding-top: 50px;
}

.login-left-container .tjunction-description-heading span,
.auth-container .auth-form .highlight {
	color: #4285F4;
}

.login-left-container .tjunction-description .tjunction-description-content {
	padding-top: 20px;
	font-size: 15px;
	padding-bottom: 30px;
}

.login-left-container .testimonial h4 {
	text-decoration: underline #1A73E8;
	text-underline-position: under;
}

.login-left-container .image-container img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.form-group {
	position: relative;
  }
  
  .dropdown-select {
	appearance: none; /* Hides the default dropdown arrow */
	-moz-appearance: none;
	-webkit-appearance: none;
	position: relative;
	padding-right: 30px; /* Adds space for the custom arrow */
	color: #000; /* Dropdown text color */
  }
  
  .dropdown-arrow {
	position: absolute;
	top: 40%;
	right: 10px; /* Positions the arrow at the end */
	transform: translateY(-50%);
	pointer-events: none;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid grey; /* Grey color for the arrow */
  }
  

.shadow-container {
	background-color: #fff !important;
	border: 0.5px solid rgba(248, 247, 247, .7529411764705882);
	border-radius: 0.78em;
	padding: 5px 15px;
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
	font-size: 13px;
}

.shadow-explore {
	background-color: #fff !important;
	border: 0.5px solid rgba(248, 247, 247, .7529411764705882);
	border-radius: 0.78em;
	padding: 5px 15px;
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
	font-size: 13px;
}


.login-left-container .register-category {
	padding: 30px;
}

.login-left-container .categories-list {
	margin-top: 20px;
}

.login-left-container .categories-list .categories {
	color: #fff;
	opacity: 0.60;
	border-radius: 3.25em;
	/* padding: 15px; */
	text-align: center;
	margin: 20px 10px;
	width: 30%;
	cursor: pointer;
}

.login-left-container .categories-list .categories-title {
	margin: 15px auto;
	font-weight: bold;
	font-size: 16px;

}

.login .auth-container {
	margin: 10px 0;
}

.auth-container .shadow-container  {
	padding: 20px 30px;
	height:auto;
}

.auth-container .register-content {
	font-size: 12px;
}

.auth-container .register-content p {
	font-size: 12px;
}

.auth-container .register-content a {
	text-decoration: underline;
	font-weight: 700;
}

.auth-container .forgot-password {
	font-size: 12px;
}

.auth-container .forgot-password a {
	text-decoration: underline;
	font-weight: 500;
}

.auth-container .lastcontent {
	text-align: center;
	margin-top: 20px;
	font-size: 12px;
}

.auth-container .lastcontent a {
	text-decoration: underline;
	font-weight: 600;
}


.auth-container .lastcontent .finaltext {
	width: 90%;
	margin: auto;
	margin-top: 25px;
}

.auth-container .lastcontent .underline {
	text-decoration: underline;
	cursor: pointer;
}


.auth-container .auth-form h4 {
	font-weight: 700;
}

.auth-container .auth-form p {
	font-size: 12px;
}

.auth-container .auth-form .social-btn {
	margin: 10px 0;
}

.auth-container .auth-form .social-btn-google {
	border: 1px solid #ced4da;
	position: relative;
	padding: 5px;
	font-size: 13px;
}

.auth-container button {
	font-size: 14px;
}

.auth-container .auth-form .social-btn-google img {
	position: absolute;
	height: 14px;
	margin: 4px 0 4px -28px;
}

.auth-container .auth-form form input,
.auth-container .auth-form form select {
	font-size: 13px;
}


.auth-container .auth-form .or-text {
	overflow: hidden;
	text-align: center;
	color: #000;
	margin-top: 20px;
}

.auth-container .auth-form .or-text:before,
.auth-container .auth-form .or-text:after {
	background-color: #BEBEBE;
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.auth-container .auth-form .or-text:before {
	right: 0.5em;
	margin-left: -50%;
}

.auth-container .auth-form .or-text:after {
	left: 0.5em;
	margin-right: -50%;
}

.auth-container .auth-form label {
	font-size: 12px;
	/* text-transform: uppercase; */
	margin-bottom: 2px;
}

.auth-container .auth-form #resend-otp {
	text-transform: uppercase;
	background-color: #fff;
	font-size: 12px;
	color: #4285F4;
	cursor: pointer;
}

.auth-container .auth-form .terms_check p {
	font-size: 13px !important;
}

.login-left-container .categories-list .active {
	opacity: 1;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 40%) !important;
	border: none;

}

.login-left-container .categories-list+div a {
	border-radius: 1.25rem;
	font-size: 13px;
	padding: 5px 15px;
}

.login-left-container .categories-list .bg-img-1 {
	background-image: linear-gradient(#FCAF2A, #C7820B);
}

.login-left-container .categories-list .bg-img-2 {
	background-image: linear-gradient(#E21F1F, #AE1111);
}

.login-left-container .categories-list .bg-img-3 {
	background-image: linear-gradient(#27BFD0, #1A8591);
}

.login-left-container .categories-list .bg-img-4 {
	background-image: linear-gradient(#65D93D, #469B29);
}

.login-left-container .categories-list .bg-img-5 {
	background-image: linear-gradient(#2D48D0, #00178E);
}

.login-left-container .categories-list .bg-img-6 {
	background-image: linear-gradient(#B61FE2, #790F98);
}

.intl-tel-input {
	width: 100%;
}

.auth-container form select[name='role_type'] option:first-child {
	color: #8e8e8e;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
	display: flex !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
	padding-left: 2px !important;
}

.intl-tel-input .selected-flag .iti-flag {
	display: none !important
}

.social-btn button {
	position: relative !important;
	padding: 8px !important;
	/* font-size: 13px !important; */
	background-color: #fff !important;
	width: 100% !important;
	font-size: 14px !important;
	height: 40px !important;
	border: 1px solid #ced4da !important;
	box-shadow: none !important;
	flex-wrap: wrap !important;
}

.social-btn button span {
	padding: 0 8px !important;
}

/* .social-btn button div {
	min-width: 25%;
} */
select option:hover {
    background-color: blue !important;
    color: white;
}

.option-select:hover{
	background-color: #0d6efd !important;
	color: white;
}
.auth-form.login-form label {
	cursor: pointer;
	margin-bottom: 5px;
}

.auth-form.login-form label .active {
	color: #0d6efd;
}

.auth-form .error-message:before {
	content: "\200b";
}

.auth-form .dob {
	margin: 0 -7px;
}

.auth-form .form-check+.error-message {
	position: relative;
	top: -6px;
}

.rec .rec-carousel-item {

	border: 0.5px solid rgba(248, 247, 247, .7529411764705882);
	border-radius: 0.78em;
	/* box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) ; */



}

.rec .rec-swipable {

	padding: 30px;
}


@media (max-width: 425px) {
	.logo-image {
	  width: 120px; /* Further decrease size for small screens */
	}
  }


@media (max-width: 768px) {
	.login-left-container .categories-list .categories {
		width: 100%;
		margin: 10px;
	}


}

@media (min-width: 768px) and (max-width: 991.98px) {
	.auth.container {
		max-width: 900px;
	}

	.login-left-container .categories-list .categories {
		width: 45%;
	}
}


.rec.rec-arrow {
	display: none;
}

.testimonial .card {
	margin: 0 5px;
}

.testimonial .card .card-title {
	text-align: left;
	padding: 0 5px;
}

.testimonial .card .card-text {
	margin-bottom: 40px;
}

.testimonial .card .card-footer {
	color: #fff;
	padding: 20px 5px;
}


.testimonial .card img {
	position: absolute;
	bottom: 40px;
	left: 40%;
}

.react-tel-input .form-control {
	border: 1px solid #dee2e6 !important;
	width: -webkit-fill-available !important;
}

.reg-phone-input-css {
	height: 30px !important;
}

.login-phone-input-css {
	height: 37px !important;
	border-radius: 0.5rem !important;
}
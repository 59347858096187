.title-css {
  font-family: "Open Sans" !important;
  font-size: 24px !important;
  color: #000000 !important;
}

.subtittle-css {
  font-family: "Open Sans" !important;
  color: #595959 !important;
  font-size: 14px !important;
}

.content-css {
  color: #595959 !important;
  font-size: 13px !important;
  font-family: Open Sans !important;
}
.font-css {
  font-family: 'Open Sans';
}

.subscribe-button {
  /* background-color: #3498db; */
  background: linear-gradient(to right, #4EC5C1 13%, #1A73E8 50%) !important;
  width: -webkit-fill-available;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
}

.selected-plan {
  background-color: #d1eaff;
  border-color: #3498db;
}

.pricing-table .table {
  --bs-table-bg: transparent !important
}

/* tbody,
td,
tfoot,
th,
thead,
tr {
 
} */

.table-bg-css {
  --bs-table-bg: #fff !important;
}

.column-1 {
  width: 160px
}

.column-2 {
  width: 200px
}

.column-3 {
  width: 130px;
}

.column-4 {
  width: 130px;
  /* background: linear-gradient(180deg, rgb(180, 177, 177) 0, rgb(169, 161, 161) 100%); */
  /* background-color: #ebebeb; */
}

.active-column {
  /* background-color: #a6d7ff !important; */
  /* background: linear-gradient(179deg, rgba(0, 255, 145, .19) 1.21%, rgba(0, 255, 145, .19) 98.8%); */
  border: 2px solid #f3751b !important;
  /* border-bottom: 0px solid rgba(0, 255, 145, 0.19) !important; */
}

.column-radius-css {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.button-group-css {
  background: white;
  border-radius: 9999px;
  display: flex;
  gap: 20px;
  padding: .5rem 0.75rem;
  width: fit-content;
}

.button-css-sub {
  width: auto;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #837f7f;
}

.active-plan-style {
  color: #069a14;
  font-weight: bold;
}

.card-tittle-css {
  font-family: 'Open Sans' !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 0px;
  margin: 0px;
  text-align: left;
  color: black !important;
}

.card-tittle-css1 {
  font-family: 'Open Sans' !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 0px;
  margin: 0px;
  text-align: left;
  color: #1a73e8 !important;
}

.plan-card-container .card {
  border-radius: 10px !important;
}

.plan-card-container .card-body {
  padding: 8px 16px !important;
}

.plan-cost-css {
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
}

.plan-validity-css {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.non-active-border {
  border: 1px solid #e2e2e2;
}

.active-border {
  border: 2px solid black;
}

.pricing-table {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  margin-bottom: 2rem;
  /* overflow-y: scroll !important;
  height: 65vh !important;
  overflow-x: auto; */
}

.pricing-header h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.table-style {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  border-radius: 10px;
  table-layout: fixed;
  /* overflow: hidden; */
}

.table-style thead th {
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  /* border-right: 1px solid #fff; */
  border-right: none;
  border-bottom: none !important;
  border-top: none !important;
}

.table-style thead th:last-child {
  border-right: none;
}

.table-style thead th span {
  display: block;
  font-size: 12px;
  font-weight: normal;
  /* margin-top: 5px; */
}

.table-style tbody td {
  padding: 10px;
  font-size: 16px;
  color: #333;
  border: 1px solid #ddd;
}

.table-style tbody td:first-child {
  border-left: none;
}

.qboard-cell {
  text-align: left;
  font-weight: bold;
  border-left: none;
}

.table-style tbody td:last-child {
  border-right: none;
}

.th-button-css {
  border: none;
  border-radius: 13px;
  padding: 7px 22px;
  width: -webkit-fill-available;
  color: #fff;
  font-weight: bold;
}

.btn-color1 {
  /* background: linear-gradient(to top, #f2994a, #f2c94c); */
  background: linear-gradient(to right, #03001e, #df38ec);
}

.btn-color2 {
  /* background: linear-gradient(to top, #26d0ce, #41295a); */
  /* background: linear-gradient(to top, #00c3ff, #ffff);  */
  background: linear-gradient(to right, #03001e, #7303c0);
}

.btn-color3 {
  background: linear-gradient(to right, #280051, #0062d3);
  /* linear-gradient(to top, #8a2387, #e94057, #f27121); */
}

.btn-color4 {
  background: linear-gradient(to right, #280051, #00d3af);
}

.btn-color5 {
  background: linear-gradient(to bottom, #c94b4b, #4b134f);
}

.plan-price-css {
  color: black;
  font-weight: bold !important;
  font-size: 18px !important;
}

.th-buy-now-btn-css {
  border: none;
  border-radius: 5px;
  padding: 5px 12px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  background-color: #0b5cff;
  /* background: linear-gradient(90deg,#4ec5c1 13%,#1a73e8 50%)!important; */
  /* background: linear-gradient(45deg, #fc00ff, #00dbde); */
  width: 85px;
  cursor: pointer;
  white-space: nowrap;
}

.disable-btn {
  background-color: #5b87d7 !important;
  cursor: auto !important;
}

.table-style th,
.table-style td {
  padding: 12px;
  text-align: center;
  /* background-color: #fff; */
}

.table-style thead {
  position: sticky;
  top: 0;
  z-index: 5;
}

.table-container {
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: auto;
}

.menu-columns {
  width: auto;
}

.data-columns {
  width: auto;
}

@media (max-width: 992px) {
  .menu-columns {
    width: 250px !important;
  }

  .data-columns {
    width: 250px !important;
  }

}

.plan-duration-css {
  color:rgb(0, 5, 61);
  text-transform: lowercase;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.padding-btm-css {
  padding-bottom: 30px !important;
}
.qboard-sidebar {
	display: flex;
	flex-direction: column;
	width: 300px;
	color: #0A0A0A;
	display: inline-block;
	z-index: 101;
	border-radius: 1.25em;
	min-height: 75vh;
	background-color: #fff;
	box-shadow: 0 1em 1em rgba(0, 0, 0, .15) !important;
	padding: 0;
	border-radius: 1.25em;
	border-top: none;
	padding: 15px;
}

.submit-link {
	display: inline-block;
	color: #007bff;
	/* Blue color for the link */
	text-decoration: none;
	font-weight: bold;
	cursor: pointer;
	margin-top: 10px;
	/* Additional margin if needed */
}

.submit-link:hover {
	text-decoration: underline;
}


.qboard-sidebar .sidebar-items {
	position: relative;

}

.qboard-sidebar .sidebar-items .sidebar-icons {
	width: 1.25em;
	height: 1.25em;
	margin-right: 16px;
	cursor: pointer;
	user-select: none;
}


.sidebar-items .item {
	display: flex;
	align-content: center;
	vertical-align: middle;
	padding: 20px 8px 20px 16px;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
	cursor: pointer;
	/* border: 0.5px solid #ebe8e8; */
	border-bottom: none;
	background-color: #fff;
	font-weight: 600px;
}

.is-dark-theme #global-green:hover {
	border-radius: 7px;
	background-color: #2c2e30;
	color: #6bbc9b;
}

#global-green:hover {
	border-radius: 7px;
	background-color: hwb(156 74% 4%);
	color: #6bbc9b;
}


.is-dark-theme #global-blue:hover {
	border-radius: 7px;
	background-color: #2c2e30;
	color: #1d9bf0;
}

#global-blue:hover {
	border-radius: 7px;
	background-color: #f4f6fd;
	color: #1d9bf0;
}

.is-dark-theme #global-pink:hover {
	border-radius: 7px;
	background-color: #2c2e30;
	color: #9c007b;
}

#global-pink:hover {
	border-radius: 7px;
	background-color: #f4f6fd;
	color: #9c007b;
}

.is-dark-theme #global-purple:hover {
	border-radius: 7px;
	background-color: #2c2e30;
	color: #885ccd;
}

#global-purple:hover {
	border-radius: 7px;
	background-color: #f4f6fd;
	color: #885ccd;
}

.is-dark-theme #global-orange:hover {
	border-radius: 7px;
	background-color: #2c2e30;
	color: #d24359;
}

#global-orange:hover {
	border-radius: 7px;
	background-color: #f4f6fd;
	color: #d24359;
}

#global-pink-insidezoom:hover {
	border-radius: 7px;
	color: #9c007b;
}

#global-orange-insidezoom:hover {
	border-radius: 7px;
	color: #d24359;
}

#global-purple-insidezoom:hover {
	border-radius: 7px;
	color: #885ccd;
}

#global-green-insidezoom:hover {
	border-radius: 7px;
	color: #6bbc9b;
}

#global-blue-insidezoom:hover {
	border-radius: 7px;
	color: #1d9bf0;
}

.sidebar-edit {
	float: right;
	color: #c9ced8;
	background-color: transparent;
	font-size: .78em;
	margin-left: 3px;
	padding: 0.15rem;
	border-radius: 0.2rem;
}

.sidebar-items .item:last-child {
	font-weight: 600;
	/* border-bottom: 0.5px solid #ebe8e8; */
	box-shadow: none !important;
}

.notification {
	background-color: white;
	color: black;
	text-decoration: none;
	padding: 0px;
	position: relative;
	display: inline-block;
	border-radius: 2px;
}

.notification-dark {
	background-color: black;
	color: white;
	text-decoration: none;
	padding: 0px;
	position: relative;
	display: inline-block;
	border-radius: 2px;
}

.notification .badge {
	font-weight: normal;
	font-size: 11px;
	padding: 3px 6px;
	position: absolute;
	top: -10px;
	right: -10px;
	/* border-radius: 50%; */
	background: blue;
	color: white;
}

.notification-dark .badge {
	font-weight: normal;
	font-size: 11px;
	padding: 3px 6px;
	position: absolute;
	top: -10px;
	right: -10px;
	/* border-radius: 50%; */
	background: blue;
	color: white;
}

.qboard-sidebar .sidebar-items .collapse-left {
	/* position: absolute; */
	padding-bottom: 10px !important;
	right: -25px;
	top: 47px;
	cursor: pointer;
	border-right-color: #fff;
}

.sidebar-items .sidebar-text {
	font-weight: 600;
	font-size: 14px;
	text-transform: capitalize !important;
}

.sidebar-items .sidebar-arrow-down {
	right: 26px !important;
	position: absolute;
	font-size: 20px;
}

.qboard-sidebar.collapsed {
	width: 70px;
}

.qboard-sidebar.collapsed .sidebar-icons {
	margin-right: 0;
}

.qboard-sidebar.collapsed .sidebar-text,
.qboard-sidebar.collapsed .sidebar-arrow-down,
.qboard-sidebar.collapsed .sidebar-items .sub-item {
	display: none;
}

.qboard-sidebar.collapsed .sidebar-items .item {
	padding-left: 1px !important;
	padding-right: 1px;
	padding-top: 15px;
	padding-bottom: 15px;

}


.qboard-sidebar.collapsed .sidebar-items .triangle-left {
	transform: rotate(135deg);
	top: -5px;
}

.sidebar-items .sub-item {
	border-top: none;
	/* text-align: right; */
	display: block;
	font-size: 12px;
	font-weight: 600;
	color: #012970;
	transition: 0.3;
	padding: 10px 11px 10px 40px;
	transition: 0.3s;
}

.sidebar-items .sub-item a,
.sidebar-items .sub-item select {
	text-decoration: none;
	color: #0A0A0A;
	text-align: right;
	padding-right: 20px;
}

.sidebar-items .item svg,
.sidebar-items .item select {
	font-size: 18px;
	margin-right: 14px;
	/* margin-left: 33px; */
	margin-top: 2px;
}

.sidebar-items .item svg.small {
	font-size: 6px;
	margin-right: 13px;
}

.fa-angle-left:before {
	margin-right: 5px;
	content: "\f104";
}

.sidebar-items .item svg.edit-delete {
	font-size: 14px;
	margin-right: 0px;
	margin-top: 0px;
}

.sidebar-items .item svg.plus-icon {
	font-size: 14px;
	margin-right: 7px;
	margin-top: 0px;
	margin-left: -2px;
}

#userlink-modal svg {
	font-size: 12px;
	margin-left: 10px;
}

.sidebar-items .item.active {
	border-radius: 7px;
}


.sub-item .sidebar-arrow-down {
	font-size: 13px !important;
	padding-top: 5px;
	/* display: none; */
}

.sub-item:hover .sidebar-arrow-down {
	display: inline;
}

@media (max-width: 768px) {
	/* .qboard-sidebar {
		position: relative;
		z-index: 1;
	} */

	.qboard-sidebar .sidebar-items .triangle-left {
		display: none;
	}

	.sidebar-items .item.active,
	.sidebar-items .sub-item {
		padding-bottom: 20px !important;
	}
}

.zoom-schedule>a {
	margin-left: 10px !important;
}

.zoom-schedule.dropdown-submenu-container a.dropdown-submenu::after {
	top: 14.8px !important;
}

.zoom-schedule .dropdown-menu a,
.zoom-host .dropdown-menu a {
	margin-left: 0;
	font-size: 13px;
}

.zoom-schedule.dropdown-submenu-container .dropdown-menu,
.zoom-host.dropdown-submenu-container .dropdown-menu {
	top: -34px !important;
	margin-left: 10px !important;
	padding: 0;
}

.zoom-host>a {
	margin-left: 10px !important;
}

.zoom-host.dropdown-submenu-container a.dropdown-submenu::after {
	top: 14.8px !important;
	display: none;
}

.sidebar-items .sidebar-first-item {
	border-top-left-radius: 1.25em;
	border-top-right-radius: 1.25em;
	border-top: none;
}

.sidebar-items .sub-item.text-left {
	text-align: left !important;
}

.sidebar-items .sub-item.myprofile {
	padding: 10px 20px !important;
}

.is-dark-theme .qboard-sidebar,
.is-dark-theme .sidebar-items .item,
.is-dark-theme .sidebar-items .sub-item a,
.is-dark-theme .sidebar-items .sub-item select {
	background: black;
	color: #fff;
	border-left: none;
	border-right: none;
}

.is-dark-theme .sidebar-items .item img {
	filter: brightness(32.5);
}


#sidebar-wrapper {
	width: fit-content;
	padding-left: 0;
	padding-right: 0;
	padding-top: 138px;
}
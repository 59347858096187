/* .global-blue{background-color:#4285f4!important;color:#fff!important} */
.global-blue{background: linear-gradient(256deg, #00c9e4 0%,#007bff 100%)!important;color:#fff!important}
.global-green{background-color:#6bbc9b!important;color:#fff!important}
.global-pink{background-color:#d580b9!important;color:#fff!important}
.global-purple{background-color:#885ccd!important;color:#fff!important}
.global-grey{background-color:silver!important;color:#000}
.global-blue-right-border{border-right-color:#4285f4!important}
.global-green-right-border{border-right-color:#6bbc9b!important}
.global-pink-right-border{border-right-color:#d580b9!important}
.global-purple-right-border{border-right-color:#885ccd!important}
.global-grey-right-border{border-right-color:silver!important}
.global-blue-font,.global-blue-nav a.active{color:#4285f4!important}.global-green-font,.global-green-nav a.active{color:#6bbc9b!important}.global-pink-font,.global-pink-nav a.active{color:#d580b9!important}.global-purple-font,.global-purple-nav a.active{color:#885ccd!important}.global-grey-font,.global-grey-nav a.active{color:silver!important}.global-green-icon i,.global-green-icon img{-webkit-filter:brightness(100%) sepia(1) hue-rotate(118deg) saturate(86.5%) brightness(98%);filter:brightness(100%) sepia(1) hue-rotate(118deg) saturate(86.5%) brightness(98%)}
.global-pink-icon i,.global-pink-icon img{-webkit-filter:brightness(100%) sepia(1) hue-rotate(282deg) saturate(74.5%) brightness(107%);filter:brightness(100%) sepia(1) hue-rotate(282deg) saturate(74.5%) brightness(107%)}.global-purple-icon i,.global-purple-icon img{-webkit-filter:brightness(100%) sepia(1) hue-rotate(225deg) saturate(71.5%) brightness(98%);filter:brightness(100%) sepia(1) hue-rotate(225deg) saturate(71.5%) brightness(98%)}.global-grey-icon i,.global-grey-icon img{-webkit-filter:brightness(100%) sepia(1) hue-rotate(38deg) saturate(124.5%) brightness(115%);filter:brightness(100%) sepia(1) hue-rotate(38deg) saturate(124.5%) brightness(115%)}

#colorChooser {width: 200px; position: relative; z-index: 16; }
.color-theme li span { width:1.2em;height:1.2em;border-radius:50%; display: block; margin-right:5px; }
.color-theme li span.active-color{position: relative;top:-1px;}
.color-theme li.d-theme { vertical-align: 2px; }
.color-theme li span.active-color:before{content:"";display:inline-block;border-radius:50%;border:4px solid; margin-left: 5px;margin-bottom:5px;}
.is-dark-theme,.is-dark-theme .color-theme li span.active-color{background:#000!important}
.d-theme svg { height: 1.2em; }
.is-dark-theme,.is-dark-theme .color-theme li span.active-color{background:#000!important}
.is-dark-theme .d-theme svg,.is-dark-theme .no-data-found{color:#fff!important}

  .blue-color {
    color:blue;
  }
  
  .green-color {
    color:green;
  }
  
  .white-color {
    color:white;
  }
  
  .teal-color {
    color:teal;
  }
  
  .yellow-color {
  color:yellow;
  }

  .for-main-class
  {  margin-top: 80px; 
    margin-right: 0px;
    padding-right: 30px;    
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px}

  .for-feed 
   {
    float: left;width: 799px;
    /* float: left;
    margin-top: 10px;
    width: 865px;
    padding-right: 0px;
    z-index:-1 */
   } 

  .for-ad-menu
  { float: right;
    top: 110px;
    /* /* float: right; */
    padding-left: 0px;
    /* top: 20px; */
    /* margin-top: 40px;  */ 
  }

  .for-colour
  {
    padding-bottom: 20px;
  }

@media (max-width: 570px) {
    #colorChooser {width: 100%; text-align: right; float:none; }
    #colorChooser .color-theme { margin-bottom: 0;}
    .color-theme li span.active-color:before{ margin-right: 4px;}
}
  
.enrolled-courses-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .course-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .course-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: auto !important;
    overflow: hidden !important;
    height: 100%;
    transition: transform 0.2s;
  }
  
  .course-card:hover {
    transform: scale(1.05);
  }
  
  .course-image {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
  
  .course-details {
    padding: 15px;
  }
  
  .course-details h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .course-details p {
    font-size: 1em;
    margin-bottom: 5px;
    color: #666;
  }
  
  .online-hover {
    transition: visibility 0s, opacity 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 20px;
    /* background-color: #007bff;  */
  }
  
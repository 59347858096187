.cov-img {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 520px;
    /* Adjust the height for smaller screens */
    color: white !important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    /* Add padding to avoid content touching edges */
}

.page-container {
    text-align: center;
    z-index: 2;
    width: 50%;
    margin: 0 auto;
}

.content-wrapper {
    margin: auto;
    /* Center content horizontally */
}

.main-title {
    font-weight: 700;
    /* font-size: 50px; */
    margin-bottom: 1rem;
    font-family: "Jost", sans-serif;
    color: white !important;
}

.main-description {
    font-size: 1.25rem;
    /* Adjust font size as needed */
    margin-bottom: 2rem;
    /* Space below the description */
    color: #fff;
    font-weight: 500;
    /* Ensure text color is white for contrast */
}

.btnn {
    padding: 0.75rem 1.5rem;
    /* Button padding */
    font-size: 1rem;
    /* Adjust font size as needed */
    border: none;
    border-radius: 4px;
    /* Rounded corners */
    text-decoration: none;
    color: #fff;
    transition: border 0.3s ease;
    margin: 5px;
    /* Smooth transition for border effect */
}

.primary-btnn {
    background-color: #007bff;
    /* Primary button background color */
}

.secondary-btnn {
    background-color: #f8f9fa;
    /* Secondary button background color */
    color: #000;
    /* Secondary button text color */
}

/* Border effect on hover */
.btnn:hover {
    border: 1px solid #fff;
}

.course-card-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
    padding: 10px;
}

.course-card1,
.course-card2,
.course-card3,
.course-card4 {
    flex: 1 1 calc(25% - 40px);
    /* Makes the cards responsive and keeps them at 25% width */
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    color: #fff;
    transition: background-color 0.3s ease;
    height: 250px;
}

.course-title-font-rec {
    font-size: 18px;
    padding: 6rem 2rem;
    font-weight: 600;
}

.course-content-font-rec {
    font-size: 14px;
    font-weight: 600;
    text-align: justify !important;
    padding: 1rem;
}


.course-card1 {
    background-color: #fb3;
}

.course-card2 {
    background-color: #253d80;
}

.course-card3 {
    background-color: #79c980;
}

.course-card4 {
    background-color: #1781e9;
}

.course-card-cont .course-card1,
.course-card2,
.course-card3,
.course-card4:hover {
    cursor: pointer;
}

.caro .test-image {
    height: 80px;
    width: 98px;
    border-radius: 15px;
    margin-right: 10px;
}



.vertical-tabs {
    font-size: 16px;
    padding: 10px 0px;
    color: #000;
}

.vertical-tabs a {
    padding: 10px;
    color: #000;
}

.vertical-tabs .nav-tabs .nav-link {
    border-radius: 0;
    text-align: center;
    font-size: 16px;
    height: 40px;
    padding: 10px;
}

.nav-link:hover .fa {
    color: #5575b5 !important;
}

.vertical-tabs ul li a {
    font-weight: 600;
    cursor: pointer;
}

.vertical-tabs .nav-tabs .active {
    color: #5575b5 !important;
}

.vertical-tabs .tab-content>.active {
    display: block;
}

.vertical-tabs .nav.nav-tabs {
    border-bottom: none;
    border-right: 3px solid #253d80;
    display: block;
    float: left;
    margin-right: 20px;
    padding-right: 15px;
    min-height: 210px;
}

.vertical-tabs ul li {
    padding: 10px
}

.vertical-tabs .sv-tab-panel {
    background: #fff;
    padding: 30px;
    min-height: 210px;
    text-align: justify;
}

.vertical-tabs .sv-tab-panel h3:hover {
    cursor: pointer;
}

.vertical-tabs .sv-tab-panel p:hover {
    cursor: pointer;
}

.caro {
    margin: auto;
    background-color: #ffff;
    padding-bottom: 50px;
}

.caro .carousel {
    position: static !important;
}

.caro .carousel-slide {
    margin: auto;
}

.caro .card {
    background-color: #eaebff;
    width: 80%;
    /* Keeping your specified width */
    margin: auto;
    height: auto;
    /* Keeping your specified height */
}

.caro .crd-content {
    margin: auto;
    width: 100%;
    /* Keeping full width */
    padding: 50px;
    /* Keeping your specified padding */
    text-align: center;
    margin-bottom: 50px;
}

.caro .crd-content p {
    font-size: 23px;
    /* Keeping your specified font size */
    color: #000;
    line-height: 2;
}

.caro .crd-content .prof {
    margin-top: 10px;
}

.caro .crd-content .prof h6 {
    font-size: 18px !important;
    /* Keeping your specified font size */
    text-transform: uppercase;
    color: #0c5991;
    font-weight: 400;
    margin-bottom: 0 !important;
}

.caro .crd-content .prof p {
    font-size: 16px;
    /* Keeping your specified font size */
    color: #767676;
}

.caro .carousel-indicators ol {
    padding: 10px;
}

.caro .carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #999;
    list-style: none;
    margin: 5px;
}

.caro .carousel-indicators .active {
    background-color: #007bff;
}

.jd-overflow-css {
    height: 300px;
    overflow: auto;
    padding: 15px 15px 15px 0;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .caro .crd-content p {
        font-size: 20px;
        /* Adjusted font size for smaller screens */
        line-height: 1.8;
    }

    .caro .crd-content .prof h6 {
        font-size: 16px;
        /* Slight adjustment for readability */
    }

    .caro .crd-content .prof p {
        font-size: 14px;
    }

    .cov-img {
        height: auto;
        min-height: 50vh;
        /* Full height for small screens */
    }
}

@media (max-width: 576px) {
    .caro .crd-content p {
        font-size: 18px;
        /* Further adjustment for mobile screens */
        line-height: 1.6;
    }

    .caro .crd-content .prof h6 {
        font-size: 14px;
    }

    .caro .crd-content .prof p {
        font-size: 12px;
    }
}



@media (max-width: 1200px) {

    .course-card1,
    .course-card2,
    .course-card3,
    .course-card4 {
        flex: 1 1 calc(33% - 40px);
        /* 3 cards per row */
    }
}

@media (max-width: 768px) {

    .course-card1,
    .course-card2,
    .course-card3,
    .course-card4 {
        flex: 1 1 calc(50% - 40px);
        /* 2 cards per row */
    }

    .vertical-tabs .nav.nav-tabs {
        border-right: none;
        border-bottom: 3px solid #000;
        float: none;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        white-space: nowrap;
        margin-bottom: 20px;
        min-height: 0;
    }

    .vertical-tabs .nav-tabs .nav-link {
        flex: 1;
        text-align: center;
    }

    .vertical-tabs .sv-tab-panel {
        padding: 30px;
        min-height: auto;
    }

    .vertical-tabs ul li {
        padding: 0px
    }

}

@media (max-width: 576px) {

    .course-card1,
    .course-card2,
    .course-card3,
    .course-card4 {
        flex: 1 1 100%;
        /* 1 card per row */
    }
}


@media (max-width: 480px) {
    .vertical-tabs .nav-tabs .nav-link {
        padding: 5px;
        font-size: 14px;
        min-height: 0;
    }

    .vertical-tabs .sv-tab-panel {
        padding: 30px;
    }

    .vertical-tabs ul li {
        padding: 0px
    }
}


@media screen and (max-width: 1200px) {
    .page-container {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .page-container {
        width: 80%;
    }

    .main-title {
        font-size: 1.8rem;
    }

    .main-description {
        font-size: 1rem;
    }

    .btnn {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 576px) {
    .page-container {
        width: 90%;
    }

    .main-title {
        font-size: 1.5rem;
    }

    .main-description {
        font-size: 0.9rem;
    }

    .btnn {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    }
}

.content-wrapper {
    margin: auto;
}

.main-title {
    font-weight: 700;
    margin-bottom: 1rem;
    font-family: "Jost", sans-serif;
    font-size: 2.5rem;
    /* Adjust font-size for responsiveness */
}

.main-description {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    color: #fff;
    font-weight: 500;
}

.btnn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    transition: border 0.3s ease;
    margin: 5px;
}

.primary-btnn {
    background-color: #007bff;
}

.secondary-btnn {
    background-color: #f8f9fa;
    color: #000;
}

.btnn:hover {
    border: 1px solid #fff;
}
.remaining-menu-full {
	height: auto;
	overflow-y: auto;
	overflow-x: hidden;
	width: 253px;
	border-radius: 1em;
	border: 0.5px solid #ebe8e8;
	border-bottom: none;
	box-shadow: 0 1em 1em rgba(0, 0, 0, 0.15) !important;
	background-color: #fff;
	margin-left: 75px;
}

.ellipsis {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	/* height: 2.5em;
	line-height: 1.2em;  */
  }
  .new-hovered-card{
	position: absolute;
	top: 0;
	left: 0px;
	right: 0;
	bottom: 0;
	background-color:#4495ec;
	color: white;
	height: 100%;
	padding: "20px";
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
  }

  .button-hover:hover{
	background-color: #4495ec;
	color: white;
  }

.margin-top-80{
margin-top: 95px;
margin-bottom: 24px;
}

.training-minus {
	max-width: 15px;
	margin-left: 4px;
	top: 130px;
}

.verticaltext {
	width: 1px;
	word-wrap: break-word;
	white-space: pre-wrap;
	font-size: 12px;
	writing-mode: vertical-rl;
	margin-left: 9px;
}

.labels-font {
	font-size: 13px;
	margin-left: 8px !important;
}

.vertical-text-column {
	margin-left: 278px !important;
	border: 1px solid lightgrey;
	display: flex;
	align-items: center;
	/* height: 100px; */
	background-color: #fff;
	border-radius: 7px;
}

.right-menu {
	padding: 0px 10px;
	transition: background-color 0.2s;
	border: 0.5px solid #ebe8e8;
	width: 107%;
	margin-left: 3px;
	border-bottom: none !important;
	border-right: none !important;
	border-left: none !important;
	font-size: 14px;
}

.refresh-column {
	margin-left: 278px !important;
	border: 1px solid lightgrey;
	margin-top: 1px;
	border-radius: 10px;
}

.arrow-right {
	margin-left: -6px !important;
	z-index: 200;
	top: 161px;
	right: 241px;
	position: absolute;
}

.show-menu-option {
	opacity: 1;
}

.hide-menu-option {
	opacity: 0;
}

.shrink-card {
	width: 51px !important;
	height: 570px !important;
	overflow: hidden;
	/* margin-left: 294px !important; */
}

.vertical-col {
	position: relative;
	left: 20px;
	
}
.vertical {
	position: relative;
	width: auto !important;
	/* left: 220px; */
}

@media only screen and (max-width: 768px){
	.traningrow{
		width: 62.5px !important;
		
	}
}

.apply-z {
	z-index: 100;
}

.icon-color {
	color: lightgray !important;
}

.edit-function {
	position: absolute;
	margin-top: -58px;
	margin-bottom: 3px;
	margin-left: 21px;
}

.apply-z-again {
	z-index: 150;
	left:-195px !important;
}

.grid-section {
	margin-right: -36px; 
	height: 545px;
	margin-left: 5px;
	margin-top: 4px;
}

.thead-border {
	border-color: transparent;
}

.tbody-overflow {
	overflow: auto !important;
	height: auto !important;
}

.table-responsive {
    overflow-x: auto;
	/* height: 319px; */
}

.expand-arrow-right {
	left: 8px;
	z-index: 200;
	position: absolute;
	top: 8px;
}

.btn-modified {
	font-size: 13px !important;
	line-height: 15px !important;
}

.ag-header-cell-label .ag-header-cell-text {
	line-height: 17px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 50px !important;
}

.custom-tooltip {
	width: 150px;
	height: 70px;
	border: 1px solid cornflowerblue;
	overflow: hidden;
}

.custom-tooltip p {
	margin: 5px;
	white-space: nowrap;
}

.custom-tooltip p:first-of-type {
	font-weight: bold;
}

.ag-theme-alpine .ag-cell {
	padding-left: 1px !important;
	padding-right: 0 !important;
}

.ag-theme-alpine .ag-header-cell {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.ag-header-cell-label .ag-header-cell-text {
	text-align: center !important;

}

.ag-icon .ag-icon-menu {
	margin-right: 51px !important;
}

.batch-delete {
	/* margin-left: 8px; */
	/* margin-bottom: 4px; */
	vertical-align: middle;
	color: lightgray;
}

.grid-cell-centered{
	text-align: center !important;
	left: auto;
}
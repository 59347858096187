.contact-tab-css {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 0;
  justify-content: center;
}

.contact-active-tab-css {
  background-color: white;
  color: blue;
  font-weight: 900;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid blue;
  padding: 0.5rem 1.5rem;
  /* cursor: pointer; */
}
.contact-Inactive-tab-css{
  font-weight: 600;
  /* border: 1px solid #c9c9c9; */
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}
.header-menu-css {
  color: black;
}

.variant-success {
  background: conic-gradient(
    rgb(62, 239, 239), 
    /* hsla(165, 100%, 55%, 1) ,  */
    hsl(311, 68%, 82%) calc(var(--value) * 1%), 
    #d9d9d9 calc(var(--value) * 1%)
  ),
}

.variant-warning {
  background: 
  conic-gradient(
    hsl(180, 63%, 63%), 
    /* hsla(45, 100%, 60%, 1) ,  */
    hsla(60, 83%, 58%, 1)  calc(var(--value) * 1%), 
    #d9d9d9 calc(var(--value) * 1%)
  ),
}

.variant-danger {
  background:  conic-gradient(
    #66FF00, 
    /* rgba(255, 0, 128, 1),  */
    #008000 calc(var(--value) * 1%), 
    #d9d9d9 calc(var(--value) * 1%)
  );
}

.progress-success {
  background: linear-gradient(
   90deg,
   rgb(62, 239, 239), 
    /* hsla(165, 100%, 55%, 1)  ,  */
    hsl(311, 68%, 82%)
    calc(var(--value) * 1%), 
    #d9d9d9 calc(var(--value) * 1%),
  );
  background-size: 100% 100%;
}

.progress-warning {
  background: linear-gradient(
    90deg, 
    hsl(180, 63%, 63%),  /* First color (0 to 33%) 
    hsla(45, 100%, 60%, 1),   Second color (33% to 66%) */
    hsla(60, 83%, 58%, 1)   calc(var(--value) * 1%),  /* Third color (66% to 100%) */
    #d9d9d9 calc(var(--value) * 1%)  /* Fallback color for the remaining part */
  );
  background-size: 100% 100%;
}

.progress-danger  .progress-bar {
  background: linear-gradient(
   90deg,
   hsl(180, 63%, 63%), /* Red color starts at 0% */
  /* Blue color in the middle (50%) */
   hsla(239, 100%, 67%, 1) 
   
  ) !important;
}
/* .progress-bar-ffr .progress-bar{
  background: linear-gradient(
    90deg,
    hsl(180, 63%, 63%), 
    hsla(239, 100%, 67%, 1) ,

   ) !important;
} */

.title-container {
  /* padding: 50px 25px; */
  padding: 50px 0;
}

.title-text {
  font-weight: 700;
  /* font-size: 50px; */
  text-align: left;
  margin-bottom: 1rem;
  font-family: "Jost", sans-serif;
}

.title-content-text {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

.left-content-container {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.title-button-css {
  padding-right: 1.7rem !important;
  padding-left: 1.7rem !important;
}

.course-container-title {
  text-align: center;
  font-weight: 700;
  /* font-size: 35px; */
  margin-bottom: 2rem;
  font-family: "Jost", sans-serif;
  /* color: #ffff; */
}

.test-container-title {
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
  font-family: "Jost", sans-serif;
  color: #ffff;
}

.course-container {
  padding: 1rem 0;
}

.course-card-css {
  /* width: 450px;
  height: 200px;
  margin-right: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  background-color: #ffbb33;
  color: #ffff;
  cursor: pointer;
}

.course-card-css1 {
  /* width: 450px;
  height: 200px;
  margin-right: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  background-color: #253d80;
  color: #ffff;
  cursor: pointer;
}

.course-title-font {
  font-size: 19px;
  padding: 6rem 2rem;
  font-family: "Jost", sans-serif;
}

.course-content-font {
  font-size: 14px;
  font-weight: 400;
  padding: 2rem 2rem;
  font-family: "Jost", sans-serif;
  text-align: justify;
}

.course-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.course-card-container1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* gap: 20px; */
}

.explore-more-container {
  margin: 2rem 0;
}

.carousel-card-css {
  /* height: 135px;
  width: 300px; */
  padding: 10px;
}

.carousel-body-css {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.carousel-card-title {
  margin: 10px 0;
  text-align: left;
  font-size: 17px;
  font-weight: 700;
}

.carousel-card-text {
  /* margin: 10px 0; */
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}

.carousel-image-css {
  height: 73px;
  width: 78px;
  border-radius: 15px;
  margin-right: 10px;
}

.test-card-css {
  /* width: 400px;
  height: 200px; */
  /* margin-right: 1.5rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-container {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.test-card-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px !important;
}

.test-card-title1 {
  font-weight: 500;
  font-size: 13px;
}

.test-card-content {
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
}

.test-image-css {
  height: 60px;
  width: 78px;
  border-radius: 15px;
  margin-right: 20px;
}

.career-footer-css {
  background-color: #09325b;
  height: 350px;
  padding: 2rem 0;
  margin-bottom: 1rem;
}

.explore-more-title {
  font-weight: 700;
  font-family: "Jost", sans-serif;
}

.background-css1 {
  background-color: #cfe2f9;
  background-image: -webkit-linear-gradient(78deg, #f4f4f4 50%, #cfe2f9 50%);
}

.carousel-icon-css {
  font-size: 43px;
  margin-right: 15px;
  color: #253d80 !important;
}

.btn-get-started {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 12px 28px;
  border-radius: 50px;
}

.tableFixHead          { overflow: auto; height: 440px }
.tableFixHead thead th { position: sticky; top: -1px; z-index: 1; }
.tableFixHead tbody td { position: sticky; left: 0; }

.tableFixHead1          { overflow: auto; width: 100%; }
.tableFixHead1 thead th { position: sticky; top: -1px; z-index: 1; }
.tableFixHead1 tbody td { position: sticky; left: 0; }

@media (min-width: 576px) {
  .student-carousel .rec-item-wrapper {
    width: 478px !important;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .student-carousel .rec-item-wrapper {
    width: 320px !important;
    height: 100%;
  }
}

@media (min-width: 992px) {
  .student-carousel .rec-item-wrapper {
    width: 288px !important;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .student-carousel .rec-item-wrapper {
    width: 347px !important;
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .student-carousel .rec-item-wrapper {
    width: 409px !important;
    height: 100%;
  }
}

@media (max-width: 991px) {
  .title-container {
    text-align: center;
  }

  .button-container {
    justify-content: center;
  }
}

/* trainer page css */

.call-to-action {
  padding: 120px 0;
  position: relative;
  clip-path: inset(0);
}

.service-item .details {
  background:
    color-mix(in srgb, #ffffff, transparent 5%);
  padding: 20px 30px;
  /* margin: -100px 30px 0 30px; */
  transition: all ease-in-out 0.3s;
  position: relative;
  text-align: center;
  /* border-radius: 8px; */
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.trainer-icon-css {
  background-color: #32344d;
  border: 0 none;
  border-radius: 0;
  float: none !important;
  margin-bottom: 30px;
  margin-left: 0 !important;
  margin-top: 0;
  max-width: 80px;
  padding: 20px;
  width: 80px;
}

.tjunc-icon-container {
  padding: 20px;
  width: 80px;
  background-color: #32344d;
  margin-bottom: 10px;
}

.tjunc-card-title {
  margin: 10px 0;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
}

.tjunc-icon-css {
  font-size: 43px;
  margin-right: 15px;
  color: #ffffff !important;
}

.tjunc-hr-css {
  width: 60px;
  color: #ffffff;
  opacity: 1.0;
}

.explore-more-title1 {
  font-weight: 700;
  font-family: "Jost", sans-serif;
  color: #ffffff;
}

.tjunc-card-text {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
}

.tjunc-section-css {
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.tjunc-icon-container-hvr {
  background-color: #eeeeee;
}

.tjunc-icon-css-hvr {
  color: #27293d !important;
}

.tjunc-card-title-hvr {
  color: #27293d;
}

.tjunc-hr-css-hvr {
  color: #27293d;
}

.tjunc-card-text-hvr {
  color: #27293d;
}

.tjunc-image-css {
  height: auto;
  width: 100%;
}

.testimonial-sec-css {
  border: 1px solid #ededed;
  display: inline;
  float: left;
  padding: 10px;
  padding-bottom: 30px;
  position: relative;
  text-align: center;
}

.testimonial-card-title {
  font-size: 16px;
  font-weight: 700;
  font-family: "Jost", sans-serif;
  margin-top: 20px;
  margin-bottom: 10px !important;
}

.testimonial-card-title1 {
  font-weight: 500;
  font-size: 14px;
  font-family: "Jost", sans-serif;
}

.testimonial-card-content {
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
  font-family: "Jost", sans-serif;
}

.test-container-title1 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
  font-family: "Jost", sans-serif;
}

.service-details-content {
  font-size: 14px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
}

.service-details-title {
  font-weight: 500;
  font-family: bebas neue;
  background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 5s ease-in-out infinite;
  text-align: center;
  line-height: 0.9;
}

.title-text-trainer {
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: bebas neue;
  background: white;
  /* background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 5s ease-in-out infinite;
  text-align: center;
}

.trainer-hero-background {
  /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%); */
  background: linear-gradient(90deg,rgb(5, 84, 115) 0%, rgb(2, 64, 108) 35%,  rgba(0,212,255,1) 100%);
}

.hero-content-text {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 600;
  font-family: "Jost", sans-serif;
  /* color: #ada8a8; */
  color: #f0ec9b;
}

.hover-content-title {
  font-weight: 700;
  text-align: center;
}

.career-section h2 {
  margin-bottom: 1rem;
}

.career-section li {
  margin-bottom: 1.5rem; /* Space between list items */
}

.career-section strong {
  display: block; /* Forces the strong text to start on a new line */
  margin-bottom: 0.5rem; /* Space below the strong text */
}

.career-section ul {
  display: flex;
  flex-direction: column; /* Stacks items vertically */
}



.custom-file-input {
  border: 2px dashed #007bff; /* Change to your desired color */
  border-radius: 5px; /* Square shape */
  width: 50%; /* Full width, adjust as needed */
  padding: 10px; /* Padding for better spacing */
  box-sizing: border-box; /* Include padding in width calculation */
  cursor: pointer; /* Show pointer cursor on hover */
  height: 150px;
  text-align: center;
}

.filename-display{ 
  margin-left: 8px;
  height: 12px;
  font-size: 13px;
  color: green !important;
  margin-top: 10px;
}

.selected-title {
  margin-left: 8px;
  height: 12px;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 500;
}
/* .uploadIcon{
  margin-right: "8px"; font-size: "16px" 
} */

.custom-file-input-role{
  border: 2px dashed #007bff; /* Change to your desired color */
  border-radius: 5px; /* Square shape */
  width: 50%; /* Full width, adjust as needed */
  padding: 10px; /* Padding for better spacing */
  box-sizing: border-box; /* Include padding in width calculation */
  cursor: pointer; /* Show pointer cursor on hover */
  height: 250px;
  text-align: center;
  
}

.sort-border {
  border-radius: 5px;
  border: 2px solid #007bff;
  padding: 0;
  max-width: 720px;
  width: 100%;
 
}
.sort-nav{
  background-color: #007bff;
  max-width: 720px;
   width: 100%;
    max-height: 50px
}

.sort-navlink{
  padding: 5px 20px 28px;
  font-weight: bold;
}


.custom-input {
  border: 2px dashed #007bff; /* Change to your desired color */
  border-radius: 5px; /* Square shape */
  width:65%; /* Full width, adjust as needed */
  padding: 10px; /* Padding for better spacing */
  box-sizing: border-box; /* Include padding in width calculation */
  cursor: pointer; /* Show pointer cursor on hover */
  height: 350px;
  text-align: center;
}


@media (max-width: 768px){
  .custom-file-input {
    border: 2px dashed #007bff; /* Change to your desired color */
    border-radius: 5px; /* Square shape */
    width: 100%; /* Full width, adjust as needed */
    padding: 10px; /* Padding for better spacing */
    box-sizing: border-box; /* Include padding in width calculation */
    cursor: pointer; /* Show pointer cursor on hover */
    height: 150px;
    text-align: center;
  }

  .custom-padding{
    padding: 0px !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .custom-file-input-role{
    border: 2px dashed #007bff; /* Change to your desired color */
    border-radius: 5px; /* Square shape */
    width: 100%; /* Full width, adjust as needed */
    padding: 10px; /* Padding for better spacing */
    box-sizing: border-box; /* Include padding in width calculation */
    cursor: pointer; /* Show pointer cursor on hover */
    height: 250px;
    text-align: center;
  }
  .custom-input {
    border: 2px dashed #007bff; /* Change to your desired color */
    border-radius: 5px; /* Square shape */
    width: 100%; /* Full width, adjust as needed */
    padding: 10px; /* Padding for better spacing */
    box-sizing: border-box; /* Include padding in width calculation */
    cursor: pointer; /* Show pointer cursor on hover */
    height: 350px;
    text-align: center;
  }
 
  
}

@media (max-width: 630px){
  .sort-border {
    border-radius: 5px;
    border: 2px solid #007bff;
    padding: 0;
    max-width: 720px;
    width: 100%;
  } 
  .sort-nav{
    background-color: #007bff;
    max-width: 720px;
     width: 100%;
  
      max-height: 80px
  }
  .sort-navlink{
    padding: 5px 20px 5px;
    font-weight: bold;
  }
}

@media (max-width: 380px){
  .sort-navlink{
    padding: 5px 20px 5px;
    font-weight:bold;
    font-size: x-small;
  }
}



.flow-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.flow-chart-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goal-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  text-align: center;
}

/* .arrow-down {
  margin: 10px 0;
  font-size: 2rem;
  color: #0056b3;
} */

.arrow-down {
  position: relative;
  font-size: 2rem;
  color: #0056b3;
  display: inline-block;
  transform: rotate(0deg);
  /* text-shadow: 2px 2px 4px #1a83ec, -2px -2px 4px #3399ff; */
  margin: 20px 0;
}

.arrow-down:before {
  content: "⬇";
  font-size: 2rem;
  color: rgb(10, 96, 171);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.text-muted {
  color: #6c757d;
}

.disabled-btn {
  background-color: #b3d7ff; /* Lightened blue for disabled state */
  color: #d3d3d3; /* Lighter text color for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled button */
  pointer-events: none; /* Prevent any interaction */
  border: none;
}

.progress-bar-font{
  color: black;
   font-weight: bold
}

.job-description-processing .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.arrow-icon {
  font-size: 16px; /* Adjust size as needed */
  stroke-width: 2px; /* Increases the thickness */
  font-weight: bold
}

.job-description-processing .left-section,
.job-description-processing .right-section {
  background-color: #fff;
  padding: 20px;
  padding-top: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: 1;
  max-height: 600px;
  overflow-y: auto;
}

.job-description-processing .section-title {
  font-size: 20px;
  font-weight: bold;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 996;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 5px;
}

.job-description-processing .criterion-table {
  width: 100%;
  border-collapse: collapse;
}

.job-description-processing .criterion-table th,
.job-description-processing .criterion-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.criterion-table thead {
  position: sticky;
  top: 50px;
  z-index: 995;
  border: 1px solid #ddd;
  font-size: 14px;
}

.job-description-processing .criterion-table td {
  font-size: 12px;
}

.job-description-processing .criterion-table th {
  background-color: #f9f9f9;
  font-size: 14px;
}

.job-description-processing .select-improvement {
  margin-bottom: 20px;
}

.job-description-processing .select-improvement label {
  font-weight: bold;
  /* display: block; */
  font-size: 14px;
}

.job-description-processing .select-improvement textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}


/* Hover effect for buttons */
.job-description-processing .tooltip {
  position: relative;
  display: inline-block;
}

.job-description-processing .tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.3s;
}

.job-description-processing .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.jd-instruction {
  font-size: 12px;
  margin-left: 3px;
}


.job-description-processing .center-section {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 70%;
  margin: 0px auto;
}

.job-description-processing .center-section .center-sec {
  padding: 20px;
}

.py-2 .center-sec {
  padding: 10px;
}

.py-2 .center-sec .heading {
  font-weight: bold;
  font-size: 14px;
}

.py-2 .center-sec .heading span {
  font-size: 12px;
  font-weight: normal;
  margin-left: 5px;
}

.py-2 .center-sec .content {
  margin-left: 15px;
  margin-bottom: 20px;
  font-size: 13px;
  text-align: justify;
}

.job-description-processing .center-section .heading {
  font-weight: bold;
  font-size: 15px;
}

.job-description-processing .center-section .content {
  margin-left: 15px;
  margin-bottom: 20px;
  font-size: 13px;
  text-align: justify;
}

.job-description-processing .center-section {
  text-align: justify;
}

.job-description-processing .input-group-text {
  display: block;
}

.job-description-processing .bck-btn {
  margin-left: 15px;
  font-size: 14px;
}


/* Responsive Design */
@media (max-width: 1024px) {
  .job-description-processing .container {
    flex-direction: column;
  }

  .job-description-processing .left-section,
  .job-description-processing .right-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .job-description-processing .center-section {
    width: 100%;
  }
}

@media (max-width: 768px) {

  .job-description-processing .section-title {
    font-size: 20px;
  }

}

@media (max-width: 480px) {

  .job-description-processing .section-title {
    font-size: 18px;
  }

  .job-description-processing .criterion-table th,
  .job-description-processing .criterion-table td {
    padding: 8px;
    font-size: 14px;
  }

}